@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poiret+One&display=swap');
@import '~react-image-gallery/styles/css/image-gallery.css';

:root {
  --font-family-main: Nunito, 'Times New Roman', sans-serif;
  --font-size-m: 14px;
  --font-size-s: 12px;
  --font-line-m: 24px;
  --font-line-s: 22px;
  --font-m: var(--font-size-m) / var(--font-line-m) var(--font-family-main);
  --font-s: var(--font-size-s) / var(--font-line-s) var(--font-family-main);

  --bg-color: #0A0A09;
  --color-secondary: #CFCFCF;
  --color-primary: #FFFFFF;
  --color-additional: #C77544;
  --color-black: #141414;
  --color-footer: rgba(255, 255, 255, 0.5);
  --bg-modal: #D4D4D4;
  --bg-menu: #161616;
}

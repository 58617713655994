* {
  box-sizing: border-box;
}

html, body {
  margin: 0;
  padding: 0;
  text-decoration: none;
  outline: none;
  min-height: 100%;
  height: 100%;
}


ul, li {
  margin: 0;
  padding: 0;
}

li {
  list-style: none;
}


h1,h2,h3,h4,h5,h6 {
  padding: 0;
  margin: 0;
}

a {
  text-decoration: none;
  color: #fff;
}

button {
  padding: 0;
  border: none;
  font: inherit;
  color: inherit;
  background-color: transparent;
  cursor: pointer;
}

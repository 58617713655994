.container {
  width: 100dvw;
  height: 100%;
  padding: 0 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.title {
  margin-top: 65px;
}

.imgWrapper {
  margin-top: 45px;
  position: relative;

  & > img {
    display: block;
  }
}

.bonusBlock {
  width: 100%;
  padding: 0 20px;
}

.btnBonusWrapper {
  width: calc(100% - 20px);
  margin-bottom: 65px;
}


.container {
  position: relative;
  display: flex;
  height: 100%;
  width: 100dvw;
  padding: 0 30px;
  flex-direction: column;

  &:before {
    position: absolute;
    content: '';
    z-index: -1;
    display: block;
    background-image: url("../../images/bg_main.jpg");
    opacity: 50%;
    left: 0;
    bottom: 0;
    right: 0;
    top: 0;
    background-size: cover;
    background-position: top;
    background-repeat: no-repeat;
  }
}

.wrapper {
  margin-top: 85%;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.text, .title {
  max-width: 270px;
}

.text {
  margin-top: 20px;
  text-align: center;
}

.title {
  line-height: 66px;
  font-size: 70px;
  text-transform: none;
  font-family: "Poiret One", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.btnWrapper {
  width: calc(100% - 20px);
  margin-top: 30px;
}

.workInfo {
  margin-top: 50%;
  margin-bottom: 52px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  border-radius: 8px;
  font-weight: 500;
  height: 50px;
}

//@media screen and (max-height: 700px)  {
//  .wrapper  {
//    margin-top: 60%;
//  }
//}

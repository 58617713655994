.text {
  white-space: pre-line;
}

.primary {
  color: var(--color-primary);
}

.secondary {
  color: var(--color-secondary);
}

.additional {
  color: var(--color-additional);
}

.black {
  color: var(--color-black);
}

.footer {
  color: var(--color-footer);
}

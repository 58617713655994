.section {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.block {
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 1; /* Устанавливаем поверх других элементов */

  &:not(:last-child) {
    margin-bottom: 20px;
  }
}

.imgWrapper {
  padding: 12px;
  border-radius: 10px;
  border: 1px solid #fff;

  & > img {
    display: block;
  }
}

.textBlock {
  max-width: 170px;
  text-align: center;
  text-transform: uppercase;
}

.arrow {
  & > img {
    display: block;
  }

  transform: rotate(0deg);
  transition: transform 0.3s linear;

  &.isActive {
    transform: rotate(-180deg);
    transition: transform 0.3s linear;
  }
}

.collapse {
  overflow: hidden;
  opacity: 0;
  text-align: center;
  padding: 0 25px;
  font-size: 12px;
  color: var(--color-secondary);
  max-height: 0;
  margin-bottom: 10px;
  transition: max-height 0.1s ease, opacity 0.2s ease;

  &.open {
    opacity: 1;
    max-height: 200px;
    transition: max-height 0.1s ease, opacity 0.2s ease;
  }
}

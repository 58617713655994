@import "reset";
@import "variables/global";

body {
  color: var(--color-primary);
  background: var(--bg-color);
  height: 100dvh;
  font-family: var(--font-family-main);
}

.app {
  font-size: var(--font-size-m);
  min-height: 100vh;
  height: 100%;
  font-weight: 300;
}

@media screen and (max-width: 380px)  {
  .app {
    font-size: var(--font-size-s);
  }
}

.container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.8);
    transform: translate3d(0, 0, 0);
    opacity: 1;
    visibility: visible;
}

.close {
    opacity: 0;
    visibility: hidden;
}

.content {
    max-width: 80%;
    display: flex;
    flex-direction: column;
    background-color: var(--bg-modal);
    border: 1px solid var(--color-secondary);
    padding: 20px;
}

.btn {
    background-color: transparent;
    border-radius: 16px;
    color: var(--color-primary);
    border: 1px solid var(--color-additional);
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 700;
    text-transform: uppercase;
    font-size: 22px;
    min-height: 50px;
    min-width: 128px;
    flex-grow: 1;
    padding: 16px 0;
}

.block {
    width: 100%;
}

.content {
    display: flex;
    align-items: center;
    justify-content: center;
}

.container {
  width: 100dvw;
  padding: 0 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #141414;
}

.title {
  margin-top: 55px;
}

.contactsInfoWrapper {
  margin-top: 44px;
  min-height: 110px;
  max-width: 230px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.contentInfo {
  display: flex;
  align-items: center;

  &:not(:last-child) {
    margin-bottom: 20px;
  }
}

.iconWrapper {
  margin-right: 9px;

  & > svg {
    display: block;
    opacity: 0.5;
  }
}

.text {
  text-align: center;
  opacity: 0.5;
  display: block;
}

.linksWrapper {
  margin-top: 47px;
}
